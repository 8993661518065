.abnormalHouseholdTable {
  max-height: 180px;
  overflow: auto;
}

.abnormalHouseholdTable table {
  border-collapse: collapse;
  width: 450px;
  /* font-size: 12px; */
}

.abnormalHouseholdTable td,
th {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

.abnormalHouseholdTable th {
  text-align: center;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #d1ede7;
  color: #000000;
}

.abnormalHouseholdTable td {
  text-align: center;
  font-size: 13px;
  color: #000000;
}
