.measurement {
  width: 300px;
  flex: 1 0 auto;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}
