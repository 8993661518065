.modal-btn {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 15px;
  overflow-clip-box: rgb(255, 255, 255);
  background-color: #767676;
  color: aliceblue;
  margin-left: auto;
  margin-top: 30px;
  border-radius: 12px;
  color: white;
  padding: 14px 0px;
  border: none;
  cursor: pointer;
  width: 100%;
  outline: none;
}

.modal-btn:hover {
  opacity: 0.8;
}

.modal-btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  border: none;
}

.modal-container {
  display: flex;
  flex-direction: column;
}

.modal-title {
  /* flex: 1 0 auto; */
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  border-bottom: 2px solid rgb(200, 200, 200);
}

.modal-title p {
  margin-bottom: 20px;
}

.modal-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 20px 20px;
}

.download-checkbox-label {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.download-btn {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  overflow-clip-box: rgb(255, 255, 255);
  background-color: #565656;
  color: aliceblue;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 20px;
  border-radius: 12px;
  color: white;
  padding: 14px 20px;
  border: none;
  cursor: pointer;
  width: 20%;
  outline: none;
}

.download-btn:hover {
  opacity: 0.8;
}

.download-btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  border: none;
}
