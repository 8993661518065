.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  background-color: rgb(220, 220, 220);
  border: 0px;
  font-size: 24px;
  color: #6e6e6e;
  outline: none;
}
