.excelIcon {
  height: 22px;
  vertical-align: middle;
}
.excelIcon img {
  /* display: inline-block; */
  vertical-align: middle;
  width: 26px;
  height: 26px;
  margin-bottom: 3px;
}
