.container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.table-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 720px;
}

.left-container {
  width: 200px;
}

.legend-container {
  flex: 1 0 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.legend-title {
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: auto;
  padding: 0px 10px 0px 0px;
}

.nomarl-status-dot {
  height: 15px;
  width: 15px;
  background-color: #c7c7c7;
  border-radius: 50%;
  margin-right: 10px;
}

.as-status-dot {
  height: 15px;
  width: 15px;
  background-color: #767676;
  border-radius: 50%;
  margin-right: 10px;
}

.normal-text {
  color: #c7c7c7;
}

.as-text {
  color: #767676;
}

.pie {
  width: 150px;
  height: 200px;
}
