.asGuideContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.asDescription {
  font-size: 14px;
  /* margin: 5px; */
}
.asGuideContainerLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 30px;
}

.asGuideContainerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-left: 1px solid grey;
  padding-left: 30px;
}

.contactTitle {
  align-self: flex-start;
  text-align: start;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
}

.logoAndContactContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.companyName {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.contactNumber {
  font-size: 16px;
  font-weight: normal;
}

.solarterraceLogo {
  width: 120px;
  position: relative;
}

.solarterraceLogo > img {
  position: absolute;
  max-width: 100px;
  max-height: 55px;
  width: auto;
  height: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.enerdotLogo {
  width: 120px;
  position: relative;
}

.enerdotLogo > img {
  position: absolute;
  max-width: 100px;
  max-height: 55px;
  width: auto;
  height: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
