.sortingIcon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-left: 10px;
}
.sortingIcon img {
  /* display: inline-block; */
  width: 12px;
  height: 12px;
}
