.weatherCard {
  width: 100px;
  display: flex;
  margin: 5px;
  padding: 10px;
  text-align: center;
  font-weight: bolder;
  font-size: 1em;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 3mm;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.time {
  font-size: 0.9em;
  margin-bottom: 10px;
}

.weatherDesc {
  font-size: 0.9em;
  margin-top: 10px;
}

.weatherIcon {
  width: 60px;
  height: 40px;
  position: relative;
}

.weatherIcon > img {
  position: absolute;
  max-width: 60px;
  max-height: 40px;
  width: auto;
  height: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
