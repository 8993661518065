.card {
  flex: 1 0 auto;
  display: flex;
  margin: 5px;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 3mm;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.totalHousehold {
  align-self: flex-start;
  font-weight: bolder;
  font-size: 1em;
  color: #818181;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.donutChartContainer {
  width: 142px;
  height: 109px;
  margin-bottom: 20px;
}

.statusLegendContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin: auto; */
  padding: 5px 0px 5px 0px;
}

.normalDot {
  height: 15px;
  width: 15px;
  background-color: #79dcf8;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px 0px 10px;
}

.asDot {
  height: 15px;
  width: 15px;
  background-color: #fe9000;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px 0px 10px;
}

.statusLegendVal {
  margin-left: auto;
  margin-right: 10px;
}
