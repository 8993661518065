.todayTotalOutputTitleBar {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.todayTotalOutputChartContainer {
  display: flex;
  flex-direction: column;
  width: 616px;
  height: 222px;
}

.todayTotalOutputChartInnerContainer {
  display: flex;
  flex-direction: row;
}

.xAxisLabel {
  align-self: flex-end;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: small;
}

.yAxisLabel {
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: small;
}
