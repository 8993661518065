.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.login-form-container {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  border-radius: 8px;
  width: 300px;
}

.login-label {
  align-self: flex-start;
  margin-top: 20px;
}

.login-checkbox-label {
  margin: 20px;
}

.login-form-footer {
  margin-top: 50px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 12px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  opacity: 0.8;
}

.button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  border: none;
}

.checkbox-container {
  margin: 20px;
}

.checkbox-container input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.checkbox-container input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input[type="checkbox"] + label:before {
  content: " ";
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: -2px 8px 0 0;
  text-align: center;
  vertical-align: middle;
  background: #fafafa;
  border: 1px solid #cacece;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
}

.checkbox-container input[type="checkbox"] + label:active:before,
.checkbox-container input[type="checkbox"]:checked + label:active:before {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.checkbox-container input[type="checkbox"]:checked + label:before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 22px;
  color: black;
  background: white;
  text-align: center;
  line-height: 20px;
}
