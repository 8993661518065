.App {
  max-width: 1600px;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.navHeader {
  background-color: #019c7c;
  height: 15px;
}

.navbar {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #909291;
  max-height: 60px;
}

.navLogoContainer {
  display: flex;
  text-decoration: none;
  align-items: center;
}

.navLogo {
  width: 120px;
  height: 60px;
  position: relative;
}

.navLogo > img {
  position: absolute;
  max-width: 70%;
  max-height: 70%;
  width: auto;
  height: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.navTitle {
  width: 60%;
  color: rgb(1, 156, 124);
  font-weight: bolder;
  font-size: 1em;
}

.navItem {
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 20px;
  color: black;
  text-decoration: none;
}

.activeLink {
  color: #019c7c;
  border-bottom: 2px solid #019c7c;
}

.loginContainer {
  display: flex;
  margin-left: auto;
}

.loggedInUser {
  display: flex;
  align-items: center;
  padding-bottom: 3px;
  color: rgb(100, 100, 100);
  font-weight: bolder;
  font-size: 1.4em;
}

.navItem:hover {
  border-bottom: 2px solid #019c7c;
}

.navItem:visited {
  color: black;
}

.footer-bar {
  background-color: #69a89b;
  height: 15px;
  margin: auto;
  margin-top: 10px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-logo {
  width: 120px;
  height: 74px;
  position: relative;
}

.footer-logo > img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
