.directionOutputLegendContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}

.directionLegend {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: auto;
}

.southWestDot {
  height: 15px;
  width: 15px;
  background-color: #4a80fc;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px 0px 10px;
}

.eastSouthDot {
  height: 15px;
  width: 15px;
  background-color: #22d3bd;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 10px 0px 10px;
}

.todayAccChart {
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barChartContiner {
  width: 600px;
  height: 220px;
}

.todayAccDong {
  width: 100%;
  border-top: 1px solid black;
  padding: 10px 0px 10px 0px;
}

.todayAccTable {
  flex: 1 0 auto;
  width: 600px;
}

.levelDescription {
  margin-top: 0px;
  font-size: smaller;
}
