.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
}

.dashboard-1 {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-height: 150px;
}
.dashboard-2 {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.dashboard-2-1 {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}
.dashboard-2-1-1 {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  /* height: 100%; */
  justify-content: space-between;
}

.dashboard-2-1-2 {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.dashboard-2-2 {
  flex: 1 0 auto;
  display: flex;
}

.today-acc-card-content {
  display: flex;
  flex: 1 0 auto;
  overflow: auto;
  padding: 20px;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.card-row-1 {
  height: 180px;
}

.card {
  flex: 1 0 auto;
  display: flex;
  margin: 5px;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 3mm;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.status-legend-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  /* margin: auto; */
  padding: 5px 0px 5px 0px;
}
