.today-acc table {
  width: 100%;
  border-collapse: collapse;
}

.today-acc th {
  border: 1px solid black;
  font-size: smaller;
}

.today-acc td {
  border: 1px solid black;
  font-size: smaller;
  /* text-align: center; */
}

.today-acc .measure-cell {
  text-align: right;
  padding-right: 20px;
}
