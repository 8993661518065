.graphContainer {
  flex: 1 0 auto;
  /* width: 450px;
  height: 200px; */
  background: white;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 0px -5px rgba(0, 0, 0, 0.3); */
}

@media only screen and (max-width: 600px) {
  .graphContainer {
    width: 15vw;
    height: 8vh;
  }
}
