.households-table table {
  border-collapse: collapse;
  width: 450px;
}

.households-table td,
th {
  /* border: 1px solid #ddd; */
  padding: 8px;
}

.households-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.households-table tr:hover {
  background-color: #ddd;
}

.households-table th {
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #818181;
  color: white;
}

.fixedWidthHeader {
  width: 100px;
}

.households-table td {
  text-align: center;
  font-size: 12px;
}

.households-table .measure-cell {
  text-align: right;
  padding-right: 20px;
}

.sortingIcon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-left: 10px;
}
.sortingIcon img {
  /* display: inline-block; */
  width: 12px;
  height: 12px;
}

.normalDot {
  height: 15px;
  width: 15px;
  background-color: #79dcf8;
  border-radius: 50%;
  /* display: inline-block; */
  margin: auto;
  padding: 0;
}

.asDot {
  height: 15px;
  width: 15px;
  background-color: #fe9000;
  border-radius: 50%;
  /* display: inline-block; */
  margin: auto;
}
